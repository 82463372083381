@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lexend:wght@100&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Inter';
}

body.dark {
    background-color: #041a37;
    color: white;

    table {
        thead {
            background-color: #054EAD;
        }
    }

    input, select {
        color: black;
    }
}

/* Global Scrollbar */

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #054EAD #EBF1F9;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

*::-webkit-scrollbar-track {
    background: rgb(255, 243, 232);
    border-radius: 5px;

}

*::-webkit-scrollbar-thumb {
    background-color: rgb(126, 126, 151);
    border-radius: 20px;
    border-radius: 5px;


}


tr:nth-child(even) {
    background-color: #E2E8F0;
  }

  .stepper__step {
    border: 1px solid green !important;
    cursor: default !important;
  }

  .stepper__step + div > span {
    color:green !important;
  }
  

  


